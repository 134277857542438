<template>
  <div class="h-full w-screen">
    <LoadingState v-if="getCoachesIsLoading" />
    <ErrorState
      v-else-if="getCoachesIsError"
      :error="getCoachesError"
      :retry="fetchCoaches"
    />
    <PageSection
      v-else
      clas="flex flex-col "
    >
      <div
        :class="[
          'grid grid-cols-6 gap-12 pt-8 ',
        ]"
      >
        <div
          v-for="(coach, index) in coaches.filter((c) => c.bio)"
          :key="index"
          class="md:col-span-3 xl:col-span-2 col-span-6 flex flex-grow"
        >
          <Card
            bordered
            :background="'bg-opacity-75 bg-secondary-200'"
          >
            <template v-slot:subtitle>
              <p
                class="text-primary font-base leading-normal text-center"
                :class="[
                  'text-base',
                ]"
              >
                {{ coach.name }}
              </p>
            </template>
            <template v-slot:title>
              <div
                class="flex flex-col gap-y-2 items-center justify-center mb-6"
              >
                <Avatar
                  :source="coach.imageUrl"
                  :text="coach.name"
                  class="bg-secondary-200 my-4 w-24 h-24"
                />
                <a
                  :href="coach.instagram"
                  rel="noreferrer"
                  target="_blank"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-instagram"
                    size="lg"
                    class="opacity-80 hover:text-primary cursor-pointer"
                  />
                </a>

                <div
                  v-if="coach.tags && coach.tags.length"
                >
                  <p class="font-thin text-xs leading-6 text-secondary-200 text-center">
                    Ask me about
                  </p>
                  <p class="italic text-primary text-xs">
                    {{ coach.tags.join(",") }}
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <BlogBlocks :content="coach.bio" />
            </template>
          </Card>
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorState, LoadingState, Card, PageSection } from '@/pages/shared/index';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'CoachesPage',
  components: {
    ErrorState,
    LoadingState,
    Card,
    PageSection,
    Avatar,
    BlogBlocks
  },
  computed: {
    ...mapGetters('coaches', [ 'getCoaches', 'getCoachesIsError', 'getCoachesError', 'getCoachesIsLoading' ]),
    coaches() {
      return this.getCoaches;
    }
  },
  async created() {
    if (!this.getCoaches.length) await this.fetchCoaches();
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('coaches', [ 'fetchCoaches' ])
  }
};
</script>
